import React from 'react';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './fundRaising.style';
import aboutImg from '../../../images/about-us.png';
import LineQR from '../../../images/line-oa-qr.png';
import { useTranslation } from 'react-i18next';
import config from '../../../config';

const DesignedAndBuilt = ({ style }) => {
  const { t } = useTranslation();
  return (
    <SectionWrapper id="fund" style={style}>
      <Container>
        <ContentWrapper>
          <div className="image">
            <Image src={aboutImg} alt="About Us Image" />
          </div>
          <div className="content">
            <Heading content={t('about.topic')} />
            <Text content={t('about.contentOne')} />
            <Text content={t('about.contentTwo')} />
            <Text content={t('footer.email')} />
            <a
              href={config.lineOaLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text
                content={`${t('footer.contact.line')} : ${config.lineOaId}`}
              />
            </a>
            <img
              src={LineQR}
              alt="Line OA QR Code"
              title="Line OA QR Code"
              style={{ width: '140px', marginTop: '0px' }}
            />
          </div>
          <div className="gradientDiv"> </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default DesignedAndBuilt;
