import React from 'react';
import MainLayout from '../layouts/main';
import BannerSection from '../containers/Hosting/Banner';
import FundRaising from '../containers/CryptoModern/FundRaising';

import { useTranslation } from 'react-i18next';

export default function() {
  const { t } = useTranslation();
  return (
    <MainLayout title={t('about.title')}>
      <BannerSection content={t('about.title')} />
      <FundRaising style={{ padding: '0 0 100px 0' }} />
    </MainLayout>
  );
}
